// All HOME PAGE ROUTES
// Route Specific
import { Route, Routes } from 'react-router-dom';

import Home from '../views/pages/Home';
import PrivacyPolicy from '../views/pages/PrivacyPolicy';
import Gallery from '../views/pages/Gallery';
import ViewGallery from '../views/pages/ViewGallery';

const App = () => {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/gallery/:userId' element={<Gallery />} />
      <Route
        path='/gallery/view/:userId/:artworkId'
        element={<ViewGallery />}
      />
      <Route path='/privacy-policy' element={<PrivacyPolicy />} />
    </Routes>
  );
};

export default App;
