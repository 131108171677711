const Spinner = () => {
  return (
    <div className='d-flex justify-content-center align-items-center vh-100 vw-100'>
      <div
        className='spinner-border text-primary'
        role='status'
        style={{ width: '3rem', height: '3rem' }}
      ></div>
      <div className='ms-3 text-primary h1'>불러오는 중...</div>
    </div>
  );
};

export default Spinner;
