// Artwork model
export interface Artwork {
  id: number;
  imageUrl: string;
  title: string;
  artist: string;
  description: string;
  audioUrl?: string;
  profileUrl?: string;
}
export interface UserSimpleInfo {
  id: number;
  nickname: string;
  authority: number;
  loginType: string;
}

export class ArtworkApiService {
  private static baseUrl = 'https://creaseum.com/api';

  // Fetch artwork by ID
  static async readById(id: number): Promise<Artwork | null> {
    const url = `${this.baseUrl}/artworks/id/${id}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
      });
      const decodedBody = await response.json();
      if (decodedBody.isSuccess) {
        return decodedBody.result[0] as Artwork;
      } else {
        console.error('Request failed:', decodedBody.message);
        return null;
      }
    } catch (error) {
      console.error('An error occurred:', error);
      return null;
    }
  }

  // Fetch artwork list by user ID
  static async readListByUserId(userId: string): Promise<Artwork[] | null> {
    const url = `${this.baseUrl}/artworks/list/user_id/${userId}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
      });
      const decodedBody = await response.json();
      if (decodedBody.isSuccess) {
        return decodedBody.result.map((jsonItem: any) => ({
          id: jsonItem.id,
          imageUrl: jsonItem.image_url,
          title: jsonItem.title,
          artist: jsonItem.artist,
          description: jsonItem.description,
          audioUrl: jsonItem.audio_url,
          profileUrl: jsonItem.profile_url,
        }));
      } else {
        console.error('Request failed:', decodedBody.message);
        return null;
      }
    } catch (error) {
      console.error('An error occurred:', error);
      return null;
    }
  }

  // Fetch artwork list by user ID
  static async checkIdExist(userId: string): Promise<UserSimpleInfo | null> {
    const url = `${this.baseUrl}/user/check/id/${userId}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
      });
      const decodedBody = await response.json();
      if (decodedBody.isSuccess) {
        return decodedBody.result[0];
      } else {
        console.error('Request failed:', decodedBody.message);
        return null;
      }
    } catch (error) {
      console.error('An error occurred:', error);
      return null;
    }
  }

  // Fetch artwork list by user ID
  static async getLikesCountByArtworkId(
    artworkId: number
  ): Promise<number | null> {
    const url = `${this.baseUrl}/likes/count/${artworkId}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
      });
      const decodedBody = await response.json();
      if (decodedBody.isSuccess) {
        return decodedBody.result[0].count;
      } else {
        console.error('Request failed:', decodedBody.message);
        return null;
      }
    } catch (error) {
      console.error('An error occurred:', error);
      return null;
    }
  }

  static async isLiked(
    userId: string,
    artworkId: number
  ): Promise<boolean | null> {
    const url = `${this.baseUrl}/isLiked?user_id=${userId}&artwork_id=${artworkId}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
      });
      const decodedBody = await response.json();
      if (decodedBody.isSuccess) {
        return true;
      } else {
        console.error('Request failed:', decodedBody.message);
        return false;
      }
    } catch (error) {
      console.error('An error occurred:', error);
      return null;
    }
  }
}
