import React, { useEffect, useCallback } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { easing } from 'maath';

const Camera: React.FC<{
  cameraQuaternion: THREE.Quaternion;
  scene: THREE.Group | null;
}> = ({ cameraQuaternion, scene }) => {
  const { camera, gl } = useThree();
  const raycaster = new THREE.Raycaster();
  const getClickedPosition = useCallback(
    (event: MouseEvent) => {
      if (!scene) return;

      const mouse = new THREE.Vector2();
      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

      raycaster.setFromCamera(mouse, camera);

      const intersects = raycaster.intersectObjects(scene.children, true);

      if (intersects.length > 0) {
        const intersect = intersects[0];
        console.log('클릭된 위치:', intersect.point);
      }
    },
    [scene, raycaster, camera]
  );

  useFrame((state, dt) => {
    easing.dampQ(state.camera.quaternion, cameraQuaternion, 0.1, dt);
  });

  useEffect(() => {
    const handleClick = (event: MouseEvent) => getClickedPosition(event);
    gl.domElement.addEventListener('click', handleClick);

    return () => {
      gl.domElement.removeEventListener('click', handleClick);
    };
  }, [gl.domElement, getClickedPosition]);

  return null;
};

export default Camera;
