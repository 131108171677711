import React, { useEffect, useState, useRef } from 'react';
import {
  Button,
  Col,
  Container,
  Image,
  Navbar,
  ProgressBar,
  Row,
  Carousel,
  CarouselItem,
} from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { ArtworkApiService, Artwork } from '../../models/Artwork';
import { FaPlay, FaPause } from 'react-icons/fa6';
import { FaHeart } from 'react-icons/fa';

const ViewGallery: React.FC = () => {
  const { userId, artworkId } = useParams<{
    userId: string;
    artworkId: string;
  }>();

  const [artworkList, setArtworkList] = useState<Artwork[]>([]);
  const [selectedArtwork, setSelectedArtwork] = useState<Artwork | null>(null);
  const [likeCount, setLikeCount] = useState(0);
  const [audioPlayer, setAudioPlayer] = useState<HTMLAudioElement | null>(null);
  const [playProgress, setPlayProgress] = useState(0);
  const [userNickname, setUserNickname] = useState('익명');
  const [slideIndex, setSlideIndex] = useState(0);

  const [formattedCurrentTime, setFormattedCurrentTime] = useState('00:00');
  const [formattedTotalTime, setFormattedTotalTime] = useState('00:00');

  // 초기 데이터 로드
  useEffect(() => {
    initFetch();
    getUserNickname(userId!);
  }, []);

  useEffect(() => {
    if (artworkId) {
      if (parseInt(artworkId) >= 0 && artworkList.length > 0) {
        const localIndex = artworkList.findIndex(
          (artwork) => artwork.id === parseInt(artworkId)
        );
        if (localIndex >= 0) {
          selectArtwork(localIndex);
          setSlideIndex(localIndex);
        }
      }
    }
  }, [artworkList]);

  const initFetch = async () => {
    await loadGalleryData();
  };

  const loadGalleryData = async () => {
    const response = await ArtworkApiService.readListByUserId(userId!);
    if (response) {
      setArtworkList(response);
    }
  };

  const getUserNickname = async (id: string) => {
    const response = await ArtworkApiService.checkIdExist(id);
    if (response) setUserNickname(response.nickname);
  };

  const selectArtwork = (index: number) => {
    const artwork = artworkList[index];
    setSelectedArtwork(artwork);
    loadLikesInfo(artwork.id);
  };

  const loadLikesInfo = async (artworkId: number) => {
    const count = await ArtworkApiService.getLikesCountByArtworkId(artworkId);
    if (count) setLikeCount(count);
  };

  const onClickAudio = () => {
    // 이미 오디오가 재생 중인 경우 함수 종료
    if (audioPlayer && !audioPlayer.paused) {
      audioPlayer?.pause();
      setPlayProgress(0);
      return;
    }
    if (!selectedArtwork?.audioUrl) return;
    const audio = new Audio(selectedArtwork.audioUrl);
    setAudioPlayer(audio);
    audio.play();
    audio.ontimeupdate = () => {
      setPlayProgress((audio.currentTime / audio.duration) * 100);

      // 시간 형식 업데이트
      const currentTime = formatTime(audio.currentTime);
      const totalTime = formatTime(audio.duration);
      setFormattedCurrentTime(currentTime);
      setFormattedTotalTime(totalTime);
    };
    audio.onended = () => {
      setPlayProgress(0);
    };
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes < 10 ? '0' : ''}${minutes}:${
      remainingSeconds < 10 ? '0' : ''
    }${remainingSeconds}`;
  };
  const handleShare = () => {
    const url = window.location.href;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        alert('링크가 클립보드에 복사되었습니다!');
      })
      .catch((err) => {
        console.error('복사 실패:', err);
      });
  };

  const handleLinkClick = () => {
    // 사용자에게 경고 메시지 표시
    const userConfirmed = window.confirm(
      '해당 기능은 앱에서만 사용 가능합니다. 앱으로 이동하시겠습니까?'
    );

    if (userConfirmed) {
      const customSchemeUrl = `creaseum://creaseum.com/view_gallery?artworkId=${artworkId}&userId=${userId}`;

      // 1. 앱을 여는 시도
      window.location.href = customSchemeUrl;

      // 2. 일정 시간 후 앱이 없는 경우 다운로드 페이지로 리디렉션
      setTimeout(() => {
        window.location.href = 'https://creaseum.com/download'; // 앱 미설치 시 다운로드 페이지로 이동
      }, 1500); // 대기 시간을 1.5초로 설정하여 사용자 경험을 최적화
    } else {
      // 사용자가 '취소'를 눌렀을 때의 처리 (아무 것도 하지 않음)
      console.log('사용자가 앱 이동을 취소했습니다.');
    }
  };
  return (
    <Container
      fluid
      className='d-flex justify-content-center align-items-center h-100 p-0'
    >
      <Col lg={4} md={6} sm={12} xs={12} className='d-flex flex-column h-100 '>
        <Navbar
          variant='dark'
          className='d-flex justify-content-center'
          style={{ backgroundColor: '#6BC6C3' }}
        >
          <Navbar.Brand>{userNickname}님의 갤러리</Navbar.Brand>
        </Navbar>

        <Carousel
          className='d-flex  overflow-auto'
          controls={true}
          indicators={false}
          interval={null}
          onSelect={(index) => {
            selectArtwork(index);
            setSlideIndex(index);
          }} // 슬라이드 변경 시 artwork 선택
          activeIndex={slideIndex}
        >
          {artworkList.map((artwork, index) => (
            <CarouselItem key={artwork.id}>
              <div className='d-flex flex-column align-items-center'>
                <Image
                  src={artwork.imageUrl}
                  alt={artwork.title}
                  fluid
                  className='w-100 position-relative'
                  style={{
                    height: '65vh',
                    objectFit: 'cover',
                  }} // BoxFit.cover와 같은 효과
                />
              </div>
            </CarouselItem>
          ))}
        </Carousel>
        {selectedArtwork ? (
          <div
            className='d-flex flex-column flex-grow-1 px-5  py-3 justify-content-between position-relative'
            style={{ backgroundColor: '#EAF8F7' }}
          >
            {/* 프로필 이미지 (왼쪽 상단에 배치) */}
            <div
              style={{
                position: 'absolute',
                top: -40,
                left: 20,
                width: 80,
                height: 80,
                borderRadius: '50%',
                overflow: 'hidden',
              }}
            >
              <Image
                src={
                  selectedArtwork?.profileUrl || '/path/to/default/image.png'
                } // 사용자의 프로필 이미지 또는 기본 이미지
                alt='Profile'
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </div>
            <div className='d-flex justify-content-end  align-items-center'>
              <FaHeart color='#EC6869' onClick={handleLinkClick} />
              <div className='ps-2'>{likeCount}</div>
            </div>

            <div>
              <div>
                <span className='h5 fw-bold'>{selectedArtwork.title}</span>{' '}
                <span>by. {selectedArtwork.artist}</span>
              </div>
              <div className='mt-1'>"{selectedArtwork.description}"</div>
            </div>
            <div className='position-relative'>
              <div
                className='d-flex position-absolute align-items-center w-100 h-100'
                style={{ top: '0%' }}
              >
                <div className='ps-2' onClick={onClickAudio}>
                  {audioPlayer && !audioPlayer.paused ? (
                    <FaPause />
                  ) : (
                    <FaPlay />
                  )}
                </div>
                <div className='ps-2'>{formattedCurrentTime}</div>
              </div>
              <ProgressBar now={playProgress} style={{ height: 24 }} />
            </div>
            <div className='d-flex justify-content-between align-items-center'>
              <span className='h5'>no.{slideIndex + 1}</span>
              <span>
                <div
                  className='btn btn-sm rounded-5 fw-bold'
                  style={{ backgroundColor: '#6BC6C3', color: 'white' }}
                  onClick={() => {
                    const targetUserId = userId; // userId를 변수로 사용
                    const targetArtworkId = artworkId; // artworkId를 변수로 사용
                    const url = `https://creaseum.com/gallery/${targetUserId}?exhibit=${targetArtworkId}`;
                    window.location.href = url; // 해당 URL로 이동
                  }}
                >
                  3D 갤러리로 보기
                </div>
                <div
                  className='btn btn-sm border-1 border-dark bg-white rounded-5 fw-bold ms-3'
                  onClick={handleShare}
                >
                  공유하기
                </div>
              </span>
            </div>
          </div>
        ) : null}
      </Col>
    </Container>
  );
};

export default ViewGallery;
