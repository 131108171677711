import React, { useRef, useState, useLayoutEffect } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { useRoute } from 'wouter';
import {
  useTexture,
  MeshReflectorMaterial,
  useCursor,
} from '@react-three/drei';
import * as THREE from 'three';
import { easing } from 'maath';
import { useFrameContext } from '../../context/FrameContext';
import { GalleryExhibitInfo } from '../../interfaces/GalleryExhibitInfo';

interface FrameProps {
  exhibitInfo: GalleryExhibitInfo;
  onClickCallback?: () => void; // 콜백 함수 매개변수 추가
}

const Frame: React.FC<FrameProps> = ({ exhibitInfo, onClickCallback }) => {
  const image = useRef<THREE.Mesh>(null);
  const frame = useRef<THREE.Mesh>(null);

  const [, params] = useRoute('/item/:id');
  const [hovered, setHovered] = useState(false);
  const [highlightHovered, setHighlightHovered] = useState(false);
  const [imageScale, setImageScale] = useState([1, 1]);

  const { state: frameState, dispatch } = useFrameContext();
  const isActive = params?.id === exhibitInfo.id.toString();

  useCursor(hovered);

  const texture = useTexture(
    exhibitInfo.image_url ?? 'https://creaseum.com/storage/artworks/default.png'
  );

  useLayoutEffect(() => {
    if (hovered || highlightHovered) {
      dispatch({
        type: 'SET_ACTIVE_FRAME',
        frameId: exhibitInfo.id.toString(),
      });
    }
  }, [hovered, highlightHovered, dispatch, exhibitInfo.id]);

  useFrame((state, dt) => {
    if (image.current) {
      easing.damp3(
        image.current.scale,
        [
          0.95 * (!isActive && hovered ? 0.9 : 1) * imageScale[0],
          0.95 * (!isActive && hovered ? 0.9 : 1) * imageScale[1],
          1,
        ],
        0.1,
        dt
      );
    }

    if (
      frame.current &&
      frame.current.material instanceof THREE.MeshStandardMaterial
    ) {
      easing.dampC(
        frame.current.material.color,
        hovered ? '#f6bd60' : 'white',
        0.1,
        dt
      );
    }
  });

  return (
    <group
      position={exhibitInfo.position}
      rotation={[
        exhibitInfo.rotation[0],
        exhibitInfo.rotation[1],
        exhibitInfo.rotation[2],
      ]}
      onClick={() => {
        if (onClickCallback) onClickCallback();
      }}
    >
      {/* Frame */}
      {exhibitInfo.image_url && (
        <mesh
          ref={frame}
          name={exhibitInfo.id.toString()}
          onPointerOver={(e) => {
            e.stopPropagation();
            setHovered(true);
            dispatch({ type: 'SET_HOVERED', hovered: true });
          }}
          onPointerOut={() => {
            setHovered(false);
            dispatch({ type: 'SET_HOVERED', hovered: false });
          }}
          scale={[1, 1, 0.05]}
          position={[1 / 2, 0, 0]}
        >
          <boxGeometry />
          <MeshReflectorMaterial
            color='#A9A9A9'
            mirror={0.5}
            metalness={0.8}
            roughness={0.5}
          />
          <mesh scale={[0.9, 0.93, 0.9]} position={[0, 0, 0.2]}>
            <boxGeometry />
            <meshBasicMaterial toneMapped={false} fog={false} />
          </mesh>
          <mesh ref={image} position={[0, 0, 0.7]}>
            <planeGeometry args={[1, 1]} />
            <meshBasicMaterial map={texture} />
          </mesh>
        </mesh>
      )}
    </group>
  );
};

export default Frame;
