import React, { useEffect } from 'react';

import * as THREE from 'three';
import { useGLTF } from '@react-three/drei';

const Model: React.FC<{
  modelPath: string;
  setScene: (scene: THREE.Group) => void;
}> = ({ modelPath, setScene }) => {
  const { scene } = useGLTF(modelPath || '/assets/first_gallery_default.glb');

  useEffect(() => {
    if (scene) {
      setScene(scene); // Group 객체 전달
    }
  }, [scene, setScene]);

  if (!modelPath) {
    return null;
  }

  return <primitive object={scene} scale={[1, 1, 1]} rotation={[0, 0, 0]} />;
};

export default Model;
