// HighlightSphere.tsx
import React, { useRef, useState } from 'react';
import * as THREE from 'three';
import { Mesh } from 'three';
import { useFrame } from '@react-three/fiber';
import { easing } from 'maath';
import { useCursor } from '@react-three/drei';

interface HighlightSphereProps {
  position: [number, number, number];
  onClickCallback?: (position: THREE.Vector3) => void;
}

const HighlightSphere: React.FC<HighlightSphereProps> = ({
  position,
  onClickCallback,
}) => {
  const highlightRef = useRef<Mesh>(null);
  const [highlightHovered, setHighlightHovered] = useState(false);
  const [isClicked, setClicked] = useState(false);
  const [activePosition, setActivePosition] = useState<THREE.Vector3 | null>(
    null
  );

  useCursor(highlightHovered);
  useFrame((state, dt) => {
    if (
      highlightRef.current &&
      highlightRef.current.material instanceof THREE.MeshStandardMaterial
    ) {
      highlightRef.current.material.color.lerp(
        new THREE.Color(highlightHovered ? '#f6bd60' : '#A9A9A9'),
        0.1
      );
    }

    if (activePosition != null && isClicked) {
      console.log(activePosition);
      const cameraPosition = state.camera.position;
      const targetPosition = activePosition;

      // 카메라 위치와 목표 위치 간 거리 계산
      const distance = cameraPosition.distanceTo(targetPosition);

      // 특정 거리 이하일 경우 이동 완료 처리
      if (distance > 0.01) {
        // 0.01은 거리 임계값, 필요 시 조정
        easing.damp3(cameraPosition, targetPosition, 0.5, dt);
      } else {
        setClicked(false);
      }
    }
  });

  return (
    <mesh
      ref={highlightRef}
      position={new THREE.Vector3(position[0] + 1.6, 0, position[2] + 0.8)}
      onPointerOver={(e) => {
        e.stopPropagation();
        setHighlightHovered(true);
      }}
      onPointerOut={() => setHighlightHovered(false)}
      onClick={() => {
        setClicked(true);
        if (onClickCallback) {
          onClickCallback(
            new THREE.Vector3(position[0] + 1.6, position[1], position[2] + 0.8)
          );
        }
        setActivePosition(
          new THREE.Vector3(position[0] + 1.6, position[1], position[2] + 0.8)
        );
      }}
    >
      <sphereGeometry args={[0.2, 32, 32]} />
      <meshStandardMaterial color='#A9A9A9' />
    </mesh>
  );
};

export default HighlightSphere;
