import { GalleryExhibitInfo } from '../../interfaces/GalleryExhibitInfo';

const Description = ({
  galleryExhibitInfo,
  onClickCallback,
}: {
  galleryExhibitInfo: GalleryExhibitInfo;
  onClickCallback?: () => void; // 콜백 함수 매개변수 추가
}) => {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1500,
        fontSize: '32px',
        textAlign: 'center',
        borderRadius: '15px', // 전체 div의 테두리를 둥글게 만듦
        overflow: 'hidden', // 내부 요소가 테두리 밖으로 나가지 않도록 함
      }}
    >
      <div
        className='position-relative d-flex flex-column justify-content-end'
        style={{
          textAlign: 'left',
          width: '30vw',
          color: 'white',
        }}
      >
        {/* 이미지가 배경처럼 뒤에 깔리도록 설정 */}
        <img
          style={{
            borderRadius: '15px', // 전체 div의 테두리를 둥글게 만듦
          }}
          src={galleryExhibitInfo?.image_url ?? ''}
          alt=''
        />
        <div
          className='p-4'
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            borderEndEndRadius: '15px',
            borderEndStartRadius: '15px',
            position: 'absolute',
            width: '100%',
            bottom: 0,
            left: 0,
            zIndex: 1, // 이미지가 텍스트보다 뒤에 위치하도록 설정
          }}
        >
          <div className='d-flex justify-content-between'>
            <h2>{galleryExhibitInfo?.title}</h2>
            <div className='h5 fw-normal'>{galleryExhibitInfo?.artist}</div>
          </div>

          <p className='h5 fw-normal mb-4'>{galleryExhibitInfo?.description}</p>

          <audio className='w-100' controls>
            <source
              src={galleryExhibitInfo?.audio_url ?? ''}
              type='audio/mp3'
            />
            Your browser does not support the audio element.
          </audio>
        </div>

        <div className='position-absolute' style={{ top: 0, right: 0 }}>
          <div
            className='btn btn-light m-3'
            onClick={() => {
              if (onClickCallback) onClickCallback();
            }}
          >
            X
          </div>
        </div>
      </div>
    </div>
  );
};

export default Description;
