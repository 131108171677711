import { Container, Row, Col, Card, ListGroup } from 'react-bootstrap';

const PrivacyPolicy = () => {
  return (
    <Container fluid className='py-5 ' style={{ backgroundColor: '#f8f9fa' }}>
      <Row className='justify-content-center'>
        <Col md={10}>
          <Card className='shadow-sm'>
            <Card.Body>
              <Card.Title className='mb-4 text-center'>
                주식회사 선트리즈 개인정보 처리방침
              </Card.Title>
              <Card.Text className='mb-4'>
                주식회사 선트리즈(이하 "회사"라 함)는 개인정보 보호법에 따라
                이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한
                이용자의 고충을 신속하고 원활하게 처리할 수 있도록 다음과 같은
                처리방침을 두고 있습니다.
              </Card.Text>
              <Card.Text className='mb-4'>
                <strong>
                  본 개인정보 처리방침은 2024년 6월 23일부터 적용됩니다.
                </strong>
              </Card.Text>

              <Card.Subtitle className='mb-3'>
                <strong>1. 개인정보의 처리 목적</strong>
              </Card.Subtitle>
              <Card.Text className='mb-3'>
                회사는 다음의 목적을 위해 개인정보를 처리합니다. 처리된
                개인정보는 다음의 목적 이외의 용도로는 사용되지 않으며, 이용
                목적이 변경되는 경우에는 사전 동의를 구할 예정입니다.
              </Card.Text>
              <ListGroup variant='flush' className='mb-4'>
                <ListGroup.Item>
                  <strong>회원 가입 및 관리:</strong> 회원 가입 의사 확인, 본인
                  식별·인증, 회원 자격 유지·관리, 서비스 부정이용 방지, 각종
                  고지·통지, 고충 처리 등을 목적으로 개인정보를 처리합니다.
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>서비스 제공:</strong> 물품 배송, 서비스 제공,
                  계약서·청구서 발송, 콘텐츠 제공, 맞춤 서비스 제공, 본인 인증,
                  연령 인증, 요금 결제·정산 등을 목적으로 개인정보를 처리합니다.
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>마케팅 및 광고:</strong> 이벤트 정보 및 참여기회 제공,
                  광고성 정보 제공 등을 목적으로 개인정보를 처리합니다.
                </ListGroup.Item>
              </ListGroup>

              <Card.Subtitle className='mb-3'>
                <strong>2. 처리하는 개인정보의 항목</strong>
              </Card.Subtitle>
              <Card.Text className='mb-3'>
                회사는 다음의 개인정보 항목을 처리하고 있습니다.
              </Card.Text>
              <ListGroup variant='flush' className='mb-4'>
                <ListGroup.Item>
                  <strong>필수 항목:</strong> 아이디, 암호화된 비밀번호, 닉네임
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>선택 항목:</strong> 이메일 주소, 전화번호, 생년월일,
                  성별 등
                </ListGroup.Item>
              </ListGroup>

              <Card.Subtitle className='mb-3'>
                <strong>3. 개인정보의 처리 및 보유 기간</strong>
              </Card.Subtitle>
              <Card.Text className='mb-3'>
                회사는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터
                개인정보를 수집 시에 동의 받은 개인정보 보유·이용기간 내에서
                개인정보를 처리·보유합니다.
              </Card.Text>
              <ListGroup variant='flush' className='mb-4'>
                <ListGroup.Item>
                  <strong>회원 가입 및 관리:</strong> 회원 탈퇴 시까지. 단, 관계
                  법령 위반에 따른 수사·조사 등이 진행 중인 경우에는 해당
                  수사·조사 종료 시까지.
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>서비스 제공:</strong> 재화·서비스 공급 완료 및 요금
                  결제·정산 완료 시까지. 단, 법령에 따라 보존해야 하는 경우에는
                  해당 기간 동안 보존.
                </ListGroup.Item>
              </ListGroup>

              <Card.Subtitle className='mb-3'>
                <strong>4. 개인정보의 파기 절차 및 방법</strong>
              </Card.Subtitle>
              <Card.Text className='mb-3'>
                회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가
                불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다.
              </Card.Text>
              <ListGroup variant='flush' className='mb-4'>
                <ListGroup.Item>
                  <strong>파기 절차:</strong> 파기 사유가 발생한 개인정보를
                  선정하고, 개인정보 보호책임자의 승인을 받아 개인정보를
                  파기합니다.
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>파기 방법:</strong> 전자적 파일 형태의 정보는 기록을
                  재생할 수 없는 기술적 방법을 사용하여 삭제하며, 종이 문서에
                  기록된 정보는 분쇄하거나 소각하여 파기합니다.
                </ListGroup.Item>
              </ListGroup>

              <Card.Subtitle className='mb-3'>
                <strong>5. 개인정보의 제3자 제공</strong>
              </Card.Subtitle>
              <Card.Text className='mb-3'>
                회사는 정보주체의 개인정보를 원칙적으로 외부에 제공하지
                않습니다. 다만, 아래의 경우에는 예외로 합니다.
              </Card.Text>
              <ListGroup variant='flush' className='mb-4'>
                <ListGroup.Item>
                  정보주체로부터 별도의 동의를 받은 경우
                </ListGroup.Item>
                <ListGroup.Item>
                  법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와
                  방법에 따라 수사기관의 요구가 있는 경우
                </ListGroup.Item>
              </ListGroup>

              <Card.Subtitle className='mb-3'>
                <strong>
                  6. 정보주체와 법정대리인의 권리·의무 및 그 행사방법
                </strong>
              </Card.Subtitle>
              <Card.Text className='mb-3'>
                정보주체는 회사에 대해 언제든지 다음과 같은 권리를 행사할 수
                있습니다.
              </Card.Text>
              <ListGroup variant='flush' className='mb-4'>
                <ListGroup.Item>개인정보 열람 요구</ListGroup.Item>
                <ListGroup.Item>오류 등이 있을 경우 정정 요구</ListGroup.Item>
                <ListGroup.Item>삭제 요구</ListGroup.Item>
                <ListGroup.Item>처리 정지 요구</ListGroup.Item>
              </ListGroup>

              <Card.Subtitle className='mb-3'>
                <strong>7. 개인정보 보호책임자</strong>
              </Card.Subtitle>
              <Card.Text className='mb-3'>
                회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보
                처리와 관련한 정보주체의 불만 처리 및 피해 구제 등을 위하여
                아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
              </Card.Text>
              <ListGroup variant='flush' className='mb-4'>
                <ListGroup.Item>
                  <strong>개인정보 보호책임자:</strong> 정형식
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>연락처:</strong> 010-6770-3904, devdoma@creaseum.com
                </ListGroup.Item>
              </ListGroup>

              <Card.Subtitle className='mb-3'>
                <strong>8. 개인정보 처리방침의 변경</strong>
              </Card.Subtitle>
              <Card.Text>
                이 개인정보 처리방침은 시행일로부터 적용되며, 법령 및 방침에
                따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의
                시행 7일 전부터 공지사항을 통하여 고지할 것입니다.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyPolicy;
