// FrameContext.tsx
import React, { createContext, useContext, useReducer, ReactNode } from 'react';
import { Vector3 } from 'three';

// 상태 타입 정의
interface FrameState {
  hovered: boolean;
  imageScale: [number, number];
  activeFrameId: string | null;
}

// 액션 타입 정의
type FrameAction =
  | { type: 'SET_HOVERED'; hovered: boolean }
  | { type: 'SET_IMAGE_SCALE'; imageScale: [number, number] }
  | { type: 'SET_ACTIVE_FRAME'; frameId: string | null }
  | { type: 'MOVE_TO_FRAME'; position: Vector3 };

// 초기 상태 정의
const initialState: FrameState = {
  hovered: false,
  imageScale: [1, 1],
  activeFrameId: null,
};
// frameReducer에서 각 액션에 로그 추가
function frameReducer(state: FrameState, action: FrameAction): FrameState {
  console.log('Reducer action received:', action); // 어떤 액션이 전달되었는지 확인
  switch (action.type) {
    case 'SET_HOVERED':
      console.log('Hovered state updated:', action.hovered); // 상태 변경 확인
      return { ...state, hovered: action.hovered };
    case 'SET_IMAGE_SCALE':
      console.log('Image scale updated:', action.imageScale); // 상태 변경 확인
      return { ...state, imageScale: action.imageScale };
    case 'SET_ACTIVE_FRAME':
      console.log('Active frame ID updated:', action.frameId); // 상태 변경 확인
      return { ...state, activeFrameId: action.frameId };

    default:
      return state;
  }
}

// Context 생성
const FrameContext = createContext<{
  state: FrameState;
  dispatch: React.Dispatch<FrameAction>;
}>({ state: initialState, dispatch: () => null });

// Provider 컴포넌트 정의
export const FrameProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(frameReducer, initialState);

  return (
    <FrameContext.Provider value={{ state, dispatch }}>
      {children}
    </FrameContext.Provider>
  );
};

// Context 사용 훅
export const useFrameContext = () => useContext(FrameContext);
