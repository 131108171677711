import './scss/main.scss';
import Routes from './router/Routes';

import { useLocation } from 'react-router-dom';

function App() {
  const location = useLocation();
  const isGallery = location.pathname.startsWith('/gallery');

  return (
    <div className={isGallery ? 'gallery-styles' : ''}>
      <Routes />
    </div>
  );
}

export default App;
