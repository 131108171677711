export interface Exhibit {
  index: number;
  gallery_index: number;
  id: number;
  title: string;
  artist: string;
  description: string;
  image_url: string;
  audio_url: string | null;
  tags: string;
  on_created: string;
  on_updated: string;
  nickname: string;
  user_id: string;
  gallery_id: number;
  pos_x: number;
  pos_y: number;
  pos_z: number;
  rot_y: number;
  file_name: string;
}

export interface InstanceSetting {
  id: number;
  meta_gallery_type_id: number;
  file_name: string;
  type_name: string;
  init_pos_x: number;
  init_pos_y: number;
  init_pos_z: number;
  init_rot_y: number;
}

interface FetchResponse<T> {
  isSuccess: boolean;
  result: T;
  message?: string;
}

type SuccessCallback<T> = (result: T) => void;
type FailCallback = (message: string | Error) => void;

export const getExhibitsByUserId = function (
  userId: string,
  event_success: SuccessCallback<Exhibit[]>,
  event_fail?: FailCallback
): void {
  fetch(`https://creaseum.com/api/metagallery/exhibits/user_id/${userId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((json: FetchResponse<Exhibit[]>) => {
      if (json.isSuccess) {
        event_success(json.result);
      } else {
        if (event_fail) event_fail(json.message || 'Unknown error');
      }
    })
    .catch((error: Error) => {
      console.log('error:', error);
      if (typeof event_fail === 'function') {
        event_fail(error);
      }
    });
};
export const getGalleryInstanceSetting = function (
  userId: String,
  event_success: SuccessCallback<InstanceSetting[]>,
  event_fail?: FailCallback
): void {
  fetch(
    `https://creaseum.com/api/metagallery/setting/instance/user_id/${userId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
    .then((response) => response.json())
    .then((json: FetchResponse<InstanceSetting[]>) => {
      if (json.isSuccess) {
        event_success(json.result);
      } else {
        if (event_fail) event_fail(json.message || 'Unknown error');
      }
    })
    .catch((error: Error) => {
      console.log('error:', error);
      if (typeof event_fail === 'function') {
        event_fail(error);
      }
    });
};
