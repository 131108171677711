import React, { Suspense, useEffect, useState, useRef } from 'react';
import * as THREE from 'three';
import { Canvas, useThree } from '@react-three/fiber';
import { Environment, Html } from '@react-three/drei';
import * as metaGallery from '../../models/MetaGallery';
import Description from '../components/Description';
import { GalleryExhibitInfo } from '../../interfaces/GalleryExhibitInfo';
import { useParams, useSearchParams } from 'react-router-dom';
import { throttle } from 'lodash';

import Model from '../components/3DModel';
import Camera from '../components/Camera';
import Spinner from '../components/Spinner';
import { FrameProvider } from '../../context/FrameContext';
import Frame from '../components/Frame';
import HighlightSphere from '../components/HighlightSphere';
const ROTATION_SPEED_DESKTOP = 0.0015; // 데스크탑 회전 속도
const ROTATION_SPEED_MOBILE = 0.05; // 모바일 회전 속도

const Loader = () => {
  return (
    <Html center>
      <Spinner />
    </Html>
  );
};

const Gallery: React.FC = () => {
  const isTest = false;
  const { userId } = useParams<{ userId: string }>();

  // 세로 화면 감지 상태
  const [isPortrait, setIsPortrait] = useState(false);
  const [loading, setLoading] = useState(true);
  const [exhibitInfo, setExhibitInfo] = useState<GalleryExhibitInfo[]>([]);
  const [selectedExhibit, setSelectedExhibit] =
    useState<GalleryExhibitInfo | null>(null);

  const [cameraPosition, setCameraPosition] = useState(
    new THREE.Vector3(0, 0, 0)
  );
  const [cameraQuaternion, setCameraQuaternion] = useState(
    new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 1, 0), 0)
  );

  const [cameraInitPos, setCameraInitPos] = useState(
    new THREE.Vector3(0, 0, 0)
  );

  const [modelPath, setModelPath] = useState<string>('');
  const [environmentPreset, setEnvironmentPreset] = useState<
    | 'apartment'
    | 'city'
    | 'dawn'
    | 'forest'
    | 'lobby'
    | 'night'
    | 'park'
    | 'studio'
    | 'sunset'
    | 'warehouse'
    | undefined
  >('warehouse');

  const [scene, setScene] = useState<THREE.Group | null>(null);
  // 세로 화면 감지 함수
  const checkOrientation = () => {
    setIsPortrait(window.innerHeight > window.innerWidth);
  };

  useEffect(() => {
    checkOrientation(); // 컴포넌트가 마운트될 때 비율 확인
    window.addEventListener('resize', checkOrientation); // 창 크기가 바뀔 때마다 비율 확인

    return () => {
      window.removeEventListener('resize', checkOrientation); // 이벤트 리스너 정리
    };
  }, []);
  useEffect(() => {
    const loadGalleryData = async () => {
      try {
        metaGallery.getGalleryInstanceSetting(
          userId ?? 'admin',
          (result) => {
            if (result[0] != null) {
              setModelPath(`/assets/${result[0].file_name}.glb`);
              setCameraPosition(
                new THREE.Vector3(
                  result[0].init_pos_x,
                  result[0].init_pos_y,
                  result[0].init_pos_z
                )
              );
              setCameraInitPos(
                new THREE.Vector3(
                  result[0].init_pos_x,
                  result[0].init_pos_y,
                  result[0].init_pos_z
                )
              );
              setCameraQuaternion(
                new THREE.Quaternion().setFromAxisAngle(
                  new THREE.Vector3(0, 1, 0),
                  result[0].init_rot_y
                )
              );
            }
          },
          (msg) => {
            console.log(msg);
          }
        );
        metaGallery.getExhibitsByUserId(
          userId ?? 'admin',
          (result) => {
            const processedExhibitInfo: GalleryExhibitInfo[] = result.map(
              (item) => ({
                title: item.title ?? null,
                description: item.description ?? null,
                image_url: item.image_url ?? null,
                id: item.id,
                audio_url: item.audio_url ?? null,
                tags: item.tags ?? null,
                artist: item.artist ?? null,
                position: [item.pos_x, item.pos_y, item.pos_z] as [
                  number,
                  number,
                  number
                ], // 3개의 요소로 설정
                rotation: [0, item.rot_y, 0, 0] as [
                  number,
                  number,
                  number,
                  number
                ], // 4개의 요소로 설정
              })
            );

            setExhibitInfo(processedExhibitInfo);

            const imgElements = processedExhibitInfo.map((exhibit) => {
              const image = new window.Image();
              image.src = exhibit.image_url ?? '';
              return image;
            });

            const checkImagesLoaded = () => {
              if (imgElements.every((img) => img.complete)) {
                setLoading(false);
              }
            };

            imgElements.forEach((img) => {
              img.onload = checkImagesLoaded;
              img.onerror = checkImagesLoaded;
            });
          },
          (msg) => {
            console.log(msg);
          }
        );
      } catch (error) {
        console.error(error);
      }
    };

    loadGalleryData();
  }, [userId]);

  const isDragging = useRef(false);
  const previousMousePosition = useRef({ x: 0, y: 0 });
  const isMobileDevice =
    'ontouchstart' in window || navigator.maxTouchPoints > 0;
  const handlePointerMove = throttle((event: any) => {
    if (!isDragging.current) return;

    const rotationSpeed = isMobileDevice
      ? ROTATION_SPEED_MOBILE
      : ROTATION_SPEED_DESKTOP;

    const deltaX = event.clientX - previousMousePosition.current.x;
    const deltaY = event.clientY - previousMousePosition.current.y;

    const euler = new THREE.Euler(0, 0, 0, 'YXZ');
    euler.setFromQuaternion(cameraQuaternion);

    euler.y += deltaX * rotationSpeed;
    euler.x += deltaY * rotationSpeed;

    const MAX_ROTATION_X = Math.PI / 4;
    const MIN_ROTATION_X = -Math.PI / 4;
    euler.x = Math.max(MIN_ROTATION_X, Math.min(MAX_ROTATION_X, euler.x));

    setCameraQuaternion(new THREE.Quaternion().setFromEuler(euler));
    previousMousePosition.current = { x: event.clientX, y: event.clientY };
  }, 16);

  const handlePointerDown = (event: any) => {
    isDragging.current = true;
    previousMousePosition.current = { x: event.clientX, y: event.clientY };
  };

  const handlePointerUp = () => {
    isDragging.current = false;
  };

  return (
    <>
      {/* 세로 화면일 경우에만 경고 메시지 표시 */}
      {isPortrait && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
            fontSize: '24px',
            textAlign: 'center',
          }}
        >
          이 콘텐츠는 가로 화면에 최적화되어 있습니다. 기기를 회전해주세요.
        </div>
      )}
      <div
        style={{ position: 'absolute', bottom: '10%', right: '10%', zIndex: 5 }}
      ></div>

      {isTest && (
        <div
          style={{ position: 'absolute', top: '10%', right: '10%', zIndex: 5 }}
        >
          <div>
            {'카메라 위치: [' +
              cameraPosition.x.toFixed(2) +
              ',' +
              cameraPosition.y.toFixed(2) +
              ',' +
              cameraPosition.z.toFixed(2) +
              ']'}
          </div>
          <div>
            {'카메라 각도: ' +
              new THREE.Euler()
                .setFromQuaternion(cameraQuaternion, 'YXZ')
                .y.toFixed(2)}
          </div>
          <label htmlFor='environment'>조명</label>
          <select
            id='environment'
            value={environmentPreset}
            onChange={(e) =>
              setEnvironmentPreset(
                e.target.value as
                  | 'apartment'
                  | 'city'
                  | 'dawn'
                  | 'forest'
                  | 'lobby'
                  | 'night'
                  | 'park'
                  | 'studio'
                  | 'sunset'
                  | 'warehouse'
              )
            }
          >
            <option value='apartment'>Apartment</option>
            <option value='city'>City</option>
            <option value='dawn'>Dawn</option>
            <option value='forest'>Forest</option>
            <option value='lobby'>Lobby</option>
            <option value='night'>Night</option>
            <option value='park'>Park</option>
            <option value='studio'>Studio</option>
            <option value='sunset'>Sunset</option>
            <option value='warehouse'>Warehouse</option>
          </select>
        </div>
      )}

      {selectedExhibit && (
        <Description
          galleryExhibitInfo={selectedExhibit}
          onClickCallback={() => {
            setSelectedExhibit(null);
          }}
        />
      )}
      <Canvas
        dpr={[1, 1.5]}
        camera={{
          fov: 55,
          position: cameraPosition,
          quaternion: cameraQuaternion,
        }}
        shadows={true} // 그림자 렌더링 활성화
        onPointerMove={(e) => {
          e.stopPropagation();
          handlePointerMove(e);
        }}
        onPointerDown={handlePointerDown}
        onPointerUp={handlePointerUp}
        onPointerLeave={handlePointerUp} // 마우스가 캔버스를 벗어나면 드래그 종료
      >
        <Suspense fallback={<Loader />}>
          <group
          // onClick={(e) => {
          //   e.stopPropagation();
          //   const newSearchParams = new URLSearchParams(searchParams);
          //   if (clicked.current === e.object) {
          //     newSearchParams.delete('exhibit');
          //     setSelectedExhibit(null);
          //   } else {
          //     const worldPos = new THREE.Vector3();
          //     const worldQuat = new THREE.Quaternion();
          //     e.object.parent!.localToWorld(worldPos.set(5, 0, -13));
          //     e.object.parent!.getWorldQuaternion(worldQuat);

          //     newSearchParams.set('exhibit', e.object.name);
          //     exhibitInfo.find((element) => {
          //       if (e.object.name === element.id.toString()) {
          //         setSelectedExhibit({
          //           id: element.id,
          //           position: [worldPos.x, worldPos.y, worldPos.z],
          //           rotation: [
          //             worldQuat.x,
          //             worldQuat.y,
          //             worldQuat.z,
          //             worldQuat.w,
          //           ],
          //           title: element.title,
          //           description: element.description ?? '',
          //           tags: element.tags ?? '',
          //           artist: element.artist ?? '',
          //           image_url: element.image_url,
          //           audio_url: element.audio_url,
          //         });
          //       }
          //     });
          //   }
          //   setSearchParams(newSearchParams);
          // }}
          // onPointerMissed={() => {
          //   const newSearchParams = new URLSearchParams(searchParams);
          //   if (newSearchParams.has('exhibit')) {
          //     newSearchParams.delete('exhibit');
          //     setSearchParams(newSearchParams);
          //   }
          // }}
          ></group>
          <FrameProvider>
            {exhibitInfo.map((props) => (
              <Frame
                key={props.image_url}
                exhibitInfo={props}
                onClickCallback={() => {
                  setSelectedExhibit(props);
                }}
              />
            ))}
            {exhibitInfo.map((props) => (
              <HighlightSphere
                key={props.image_url}
                {...props}
                onClickCallback={(position) => {
                  setCameraPosition(position);
                }}
              />
            ))}
            <HighlightSphere
              key={'start_point'}
              position={[cameraInitPos.x, cameraInitPos.y, cameraInitPos.z]}
              onClickCallback={(position) => {
                setCameraPosition(position);
              }}
            />
          </FrameProvider>
          <Model modelPath={modelPath} setScene={setScene} />
          <Camera cameraQuaternion={cameraQuaternion} scene={scene} />
          <Environment preset={environmentPreset} />
        </Suspense>
      </Canvas>
    </>
  );
};

export default Gallery;
